<template>
  <div class="team">
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text"> اضافة راتب </h1>
      <v-form v-model="isFormValid">
        <v-row class="mt-5">
          <!-- name -->
          <v-col md="6" sm="6" cols="12">
            <v-autocomplete v-model="account_selected" :rules="rules.account_id" :loading="accountLoading"
              :items="accountData"
              :item-text="item => `${item.account_name} ( ${getAccountTypeInArabic(item.account_type)} )`" return-object
              clearable outlined dense label="اسم الموظف" @click:clear="data.account_id = null"
              @change="accountSelectChange">
            </v-autocomplete>
          </v-col>
          <!-- amount -->
          <v-col md="6" sm="6" cols="12">
            <v-text-field v-model="data.amount" @keypress="isNumber($event)" dense label="الراتب" outlined>
            </v-text-field>
          </v-col>
          <!-- additional -->
          <v-col md="12" sm="6" cols="12">
            <!-- Add purchased products -->
            <div class="add-products-form">
              <h2 class="mb-2">الزيادات</h2>
              <div v-for="(additionalItem, additionalItemIndex) in additionalItems" :key="additionalItemIndex"
                class="single-product-form">

                <v-card outlined class="d-flex">
                  <!-- Left Form -->
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-autocomplete v-model="additionalItem.counting_service_salary"
                        :loading="actionsAdditionalLoading" :items="actionsAdditionalData" item-text="name"
                        item-value="_id" clearable outlined dense label="نوع الزيادة"></v-autocomplete>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="additionalItem.price" @keypress="isNumber($event)" dense label="المبلغ"
                        outlined></v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="additionalItem.note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>


                  <!-- Item Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn icon small @click="additionalItems.splice(additionalItemIndex, 1)">
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
              <div>
                <div class="card_action">
                  <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItem">
                    اضافة زيادة اخرى
                  </v-btn>
                  <h3 class="mt-4">مبلغ الزيادات الكلي: {{ numberWithComma((allAdditional ? allAdditional : 0)) + " " +
                      currencySymbol
                  }}
                  </h3>
                </div>

              </div>
            </div>
          </v-col>

          <!-- discounts -->
          <v-col md="12" sm="6" cols="12">
            <!-- Add purchased products -->
            <div class="add-products-form">
              <h2 class="mb-2">الاستقطاعات</h2>
              <div v-for="(discountItem, discountItemIndex) in discountsItems" :key="discountItemIndex"
                class="single-product-form">

                <v-card outlined class="d-flex">
                  <!-- Left Form -->
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-autocomplete v-model="discountItem.counting_service_salary" :loading="actionsDiscountLoading"
                        :items="actionsDiscountData" item-text="name" item-value="_id" clearable outlined dense
                        label="نوع الاستقطاع"></v-autocomplete>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="discountItem.price" @keypress="isNumber($event)" dense label="المبلغ"
                        outlined></v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="discountItem.note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>


                  <!-- Item Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn icon small @click="discountsItems.splice(discountItemIndex, 1)">
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
              <div class="card_action">
                <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItemDiscount">
                  اضافة استقطاع اخر
                </v-btn>
                <h3 class="mt-4">مبلغ الاستقطاعات الكلي: {{ numberWithComma(allDiscounts ? allDiscounts : 0) + " " +
                    currencySymbol
                }}
                </h3>
              </div>
            </div>
          </v-col>
          <!-- <v-autocomplete v-model="data.account_id" :loading="actionsDiscountLoading" :items="actionsDiscountData"
            item-text="name" item-value="_id" clearable outlined dense label="نوع الاستقطاع"></v-autocomplete> -->

          <!-- lectures -->
          <v-col cols="12" class="d-flex justify-center my-5">
            <v-btn :color="!isAccountDivisionVisible ? '#607D8B' : '#FF3D00'" class="white--text"
              @click="isAccountDivisionVisible ? isAccountDivisionVisible = false : isAccountDivisionVisible = true">
              <span v-if="!isAccountDivisionVisible"> اظهار &nbsp;</span> <span
                v-else="!isAccountDivisionVisible">اخفاء&nbsp;
              </span>
              الصفوف ومبالغ المحاظرات
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="isAccountDivisionVisible">
            <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار"
              :headers="table.headers" :items="data.account_division" :items-per-page="10" item-key="_id"
              class="elevation-1" :footer-props="{
                showFirstLastPage: true,

              }">
              <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.account_name="{ item }">
                <router-link :to="'/students/studentProfile/' + item._id + '/' + item.account_name"> {{
                    item.account_name
                }} </router-link>
              </template>
            </v-data-table>
          </v-col>
          <!-- lecture -->
          <v-col md="12" sm="6" cols="12">
            <div class="add-products-form">
              <h2 class="mb-2">المحاظرات</h2>
              <div class="single-product-form">
                <v-card outlined class="d-flex">
                  <v-row>
                    <!-- <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.lectures_number" @keypress="isNumber($event)" dense
                        label="عدد المحاظرات" outlined>
                      </v-text-field>
                    </v-col> -->
                    <v-col md="4" sm="6" cols="12">
                      <v-text-field v-model="data.lecture_amount" @keypress="isNumber($event)" dense
                        label="مبلغ المحاظرات الكلي" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="8" sm="6" cols="12">
                      <v-textarea v-model="data.lectures_note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <!-- watch -->
          <v-col md="12" sm="6" cols="12">
            <div class="add-products-form">
              <h2 class="mb-2">المراقبات</h2>
              <div class="single-product-form">
                <v-card outlined class="d-flex">
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.watch_number" @keypress="isNumber($event)" dense label="عدد المراقبات"
                        outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.per_watch_price" @keypress="isNumber($event)" dense
                        label="مبلغ المراقبة الواحدة" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="data.watch_note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <!-- birthday -->
          <v-col cols="12">
            <v-menu v-model="menuAccountBirthday" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="data.payment_date" :rules="rules.payment_date" dense label="تاريخ الراتب"
                  outlined readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="data.payment_date" @input="menuAccountBirthday = false"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" class="text-center">
            <h1 class="mb-5">الراتب المستحق: {{ data.deserve_amount + " " + currencySymbol }}</h1>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-center mt-2">
        <v-btn :loading="addBtnLoading" :disabled="!isFormValid" color="primary" @click="submitAdd()"> اضافة </v-btn>
        <v-btn class="mr-5" @click="cancelAdd()"> الغاء </v-btn>
      </div>
    </v-card>
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api';
import { getCurrentDateInString } from '@/constant/date';
import numberWithComma from '@/constant/number';
import { mdiClose, mdiCogOutline } from '@mdi/js';

export default {
  data() {
    return {
      allAdditional: null,

      isAccountDivisionVisible: false,

      currencySymbol: null,

      allDiscounts: null,
      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
      },

      isFormValid: false,


      discountsItems: [
        {
          counting_service_salary: null,
          price: null,
          note: null,
        },
      ],

      additionalItems: [
        {
          counting_service_salary: null,
          price: null,
          note: null,
        },
      ],

      accountLoading: false,

      accountData: [],

      account_selected: {},

      account_salary: null,

      actionsAdditionalData: [],

      actionsAdditionalLoading: false,

      actionsDiscountData: [],

      actionsDiscountLoading: false,

      data: {
        account_id: null,
        amount: null,
        additional: [],
        discounts: [],
        lectures_number: null,
        per_lectures_price: null,
        lecture_amount: null,
        lectures_note: null,
        watch_number: null,
        per_watch_price: null,
        watch_note: null,
        payment_date: getCurrentDateInString(),
        deserve_amount: 0,
        account_division: []
      },

      rules: {
        // account_id: [value => (!!value || value == {}) || 'اسم الموظف مطلوب'],
        account_id: [v => Boolean(Object.keys(v || {})[0]) || "اسم الموظف مطلوب'"],
        amount: [v => Boolean(Object.keys(v || {})[0]) || "يجب تحديد الراتب"],
        payment_date: [value => !!value || 'يجب اختيار تاريخ تسليم الراتب'],
      },

      account_disableItems: [
        { text: 'مفعل', value: false },
        { text: 'متوقف', value: true },
      ],

      menuAccountBirthday: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      accountLoading: false,

      addBtnLoading: false,

      table: {
        loading: false,
        headers: [
          {
            text: '#',
            align: 'start',
            value: '_id',
            width: 1
          },
          {
            text: 'الصف',
            value: 'class_name'

          },
          { text: 'الشعبة', value: 'leader' },
          { text: 'عدد المحاظرات', value: 'lecture_number' },
          { text: 'مبلغ المحاظرة الواحدة', value: 'lecture_amount' },
          { text: 'المبلغ الكلي', value: 'lecture_number_amount_all' },
        ],
      }


    }
  },

  watch: {
    'data.amount': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    'data.lecture_amount': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    // 'data.per_lectures_price': {
    //   handler() {
    //     this.calculateDeserveAmount()
    //     // this.paymentChange()
    //   },
    // },

    'data.watch_number': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },


    'data.per_watch_price': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    'additionalItems': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
      deep: true
    },

    'discountsItems': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
      deep: true
    },
  },

  created() {
    const results = JSON.parse(localStorage.getItem('results'))
    this.currencySymbol = results.school_currency === "US" ? "$" : results.school_currency

    this.getEmployee()
    this.getDataActionsAdditional()
    this.getDataActionsDiscount()
  },

  methods: {
    async submitAdd() {
      if (this.data.amount === null) {
        this.showDialogfunction('يجب كتابة مبلغ الراتب', '#FF5252')
      } else {
        this.addBtnLoading = true

        const study_year = JSON.parse(localStorage.getItem('study_year'));

        let discounts = await this.filterDiscountAndAdditionalData(this.discountsItems)
        let additional = await this.filterDiscountAndAdditionalData(this.additionalItems)

        let lectures_class_school = await this.filterAccountDivisionData(this.data.account_division)


        const response = await Api.addEmployeeSalary({
          account_id: this.data.account_id,
          amount: this.data.amount,
          discounts,
          additional,
          lectures_number: this.data.lectures_number,
          per_lectures_price: this.data.per_lectures_price,
          all_lectures_price: this.data.lecture_amount,
          lectures_class_school,
          lectures_note: this.data.lectures_note,
          per_watch_price: this.data.per_watch_price,
          watch_number: this.data.watch_number,
          watch_note: this.data.watch_note,
          study_year,
          payment_date: this.data.payment_date
        })


        if (response.status === 401) {
          this.$store.dispatch('submitLogout')
        } else if (response.status === 500) {
          this.addBtnLoading = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
        } else {
          this.addBtnLoading = false
          this.showDialogfunction(response.data.results, 'primary')
          this.$router.replace('/salariesShow')
        }
      }

    },

    async filterDiscountAndAdditionalData(data) {
      let editedDate = []
      for await (const iterator of data) {
        if (iterator.price) {
          editedDate.push(iterator)
        }
      }
      return editedDate
    },

    async calculateDeserveAmount() {
      const allAdditional = await this.calculateAllAdditional();
      const allDiscounts = await this.calculateAllDiscounts();
      this.allAdditional = allAdditional
      this.allDiscounts = allDiscounts
      this.data.deserve_amount = (+(this.data.amount) ? +(this.data.amount) : 0) + (allAdditional ? allAdditional : 0) + (+(this.data.lecture_amount) ? +(this.data.lecture_amount) : 0) + ((+(this.data.watch_number) ? +(this.data.watch_number) : 0) * (+(this.data.per_watch_price) ? +(this.data.per_watch_price) : 0)) - (allDiscounts ? allDiscounts : 0)

      this.data.deserve_amount = this.numberWithComma(this.data.deserve_amount)
    },

    async filterAccountDivisionData(data) {
      let editedDate = []
      for await (const iterator of data) {
        if (iterator.class_name) {
          editedDate.push({
            class_school_id: iterator._id,
            lecture_amount: +(iterator.lecture_amount) ? +(iterator.lecture_amount) : 0,
            lecture_number: iterator.lecture_number,
          })
        }
      }
      return editedDate
    },

    async calculateAllAdditional() {
      let results = 0;
      for await (const iterator of this.additionalItems) {
        results += +(iterator.price)
      }
      return results;
    },

    async calculateAllDiscounts() {
      let results = 0;
      for await (const iterator of this.discountsItems) {
        results += +(iterator.price)
      }
      return results;
    },

    async getEmployee() {
      this.accountLoading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))
      const response = await Api.getEmployee(study_year)

      if (response.status === 401) {
        this.accountLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.accountLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.accountLoading = false
        this.accountData = response.data.results
      }
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    addNewItem() {
      this.additionalItems.push({
        counting_service_salary: '',
        price: null,
        note: '',
      })
    },

    addNewItemDiscount() {
      this.discountsItems.push({
        counting_service_salary: '',
        price: null,
        note: '',
      })
    },

    async getDataActionsAdditional() {
      this.actionsAdditionalLoading = true

      const response = await Api.getActions("زيادة")

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.actionsAdditionalLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.actionsAdditionalLoading = false
        this.actionsAdditionalData = response.data.results
      }
    },

    async getDataActionsDiscount() {
      this.actionsDiscountLoading = true

      const response = await Api.getActions("استقطاع")

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.actionsDiscountLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.actionsDiscountLoading = false
        this.actionsDiscountData = response.data.results
      }
    },

    cancelAdd() {
      window.history.back()
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    getAccountTypeInArabic(account_type) {
      switch (account_type) {
        case "accountant": return "محاسب";
        case "manager": return "مدير";
        case "driver": return "سائق";
        case "teacher": return "استاذ";
        case "nightGard": return "حارس ليلي";
        case "gard": return "حارس";
        case "marketEmployee": return "موظف الماركت";
        case "trackingEmployee": return "مسؤول المتابعة";
        case "informationEmployee": return "موظف الاستعلامات";
        case "healthOrganizer": return "منسق صحي";
        case "assistance": return "سكرتير";
        case "gymTrainer": return "مدرب الجم";
        case "marketingManger": return "مدير التسويق";

        default: return "موظف";
      }
    },

    accountSelectChange() {
      if (this.account_selected === null) {
        this.data.account_id = null

        this.data.amount = null
      } else {

        this.data.account_id = this.account_selected._id

        this.data.amount = this.account_selected.account_salary ? this.account_selected.account_salary : null

        this.data.lectures_number = this.account_selected.lectureNumber ? this.account_selected.lectureNumber : null

        this.data.lecture_amount = this.account_selected.lecture_amount ? this.account_selected.lecture_amount : null

        this.data.account_division = this.account_selected.account_division ? this.account_selected.account_division : []
      }
    },

    numberWithComma,
  },
}
</script>

<style scoped>
.card_action {
  display: flex;
  align-content: center;
}
</style>

